import React from 'react';

import styles from './NotificationMobilePage.module.scss';

import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import NotificationBar from '@components/NotificationBar/NotificationBar';

const NotificationMobilePage = () => {
  return (
    <main className={styles.page}>
      <Header pageName={'Уведомления'} />
      <div className={styles.notification}>
        <NotificationBar />
      </div>
      <Footer />
    </main>
  );
};

export default ProtectedRoute(NotificationMobilePage);
